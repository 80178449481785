import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formFields, getConfigFormModalSelect } from "./form-config";
import { getColumns } from "./columns";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import TableBasic from "../../components/TableBasic";
import {
  useLazyGetTasksListQuery,
  useLazyPrintTaskListQuery,
} from "../../../middleware/tasksApi";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import TaskDialog from "./TaskDialog/TaskDialog";
import { globalFields, CHART_DATA } from "../../../constants";
import SetNewPasswordDialog from "../Profile/SetNewPasswordDialog";
import { STATUS_ID, fields } from "./constants";
import { PATHS } from "../../../constants";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import { CircularProgress } from "@mui/material";
import PageheaderNav from "../../components/PageheaderNav";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import exporticon from "../../../image/icons/exportBtnWhite.png";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import PrintIcon from "../../components/icons/PrintIcon";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { dateFormatForSubmit } from "../../../constants";
import useScreenSize from "../../../hooks/useScreenSize";
import { getTableHieght } from "../../../helpers/getTableHieght";

const TasksList = () => {
  const { t } = useTranslation();
  const [getParams, setSearchParams] = useSearchParams();

  const {
    dateRangeStorage,
    jobNameStorage,
    responsibleCustomerStorage,
    executiveCustomerStorage,
    searchAddressStorage,
    defectActNumberStorage,
    replacementTaskStorage,
    equipmentNumberAndNameStorage,
    protectionObjectIdStorage,
    priorityStorage,
    statusStorage,
    deadlineStatusStorage,
    equipmentTypeStorage,
    deadLineDateStorage,
  } = JSON.parse(sessionStorage.getItem("tasksListSearchParams")) || {};

  const equip = getParams.has("equip") ? getParams.get("equip") : null;
  const notif = getParams.has("notif") ? getParams.get("notif") : null;
  const facilityName = getParams.has("facilityName")
    ? getParams.get("facilityName")
    : protectionObjectIdStorage || "";
  const status = getParams.has("status") ? getParams.get("status") : "";
  const equipmentTypeId = getParams.has("EquipmentType")
    ? getParams.get("EquipmentType")
    : equipmentTypeStorage || "";
  const startDate = getParams.has("startDate")
    ? getParams.get("startDate")
    : null;
  const endDate = getParams.has("endDate") ? getParams.get("endDate") : null;

  const equipmentId = getParams.has("equipmentId")
    ? getParams.get("equipmentId")
    : "";
  const equipmentName = getParams.has("equipmentName")
    ? getParams.get("equipmentName")
    : "";
  const executiveUser = getParams.has("executiveUser")
    ? getParams.get("executiveUser")
    : "";
  const responsibleUser = getParams.has("responsibleUser")
    ? getParams.get("responsibleUser")
    : "";
  const responsibleUserId = getParams.has("responsibleUserId")
    ? getParams.get("responsibleUserId")
    : "";
  const OnlyOpenTasks = getParams.has("OnlyOpenTasks") ? false : true;
  const navigate = useNavigate();
  const { tasks } = useAppSelector((state) => state.tasks);
  const [PageNumber, changePageNumber] = useState(0);

  const [equipmentNumberAndName, setEquipmentNumberAndName] = useState(
    equipmentName || equipmentNumberAndNameStorage
      ? equipmentName || equipmentNumberAndNameStorage
      : ""
  );

  const [jobName, setJobName] = useState(jobNameStorage || "");
  const [responsibleCustomer, setResponsibleCustomer] = useState(
    responsibleCustomerStorage || ""
  );
  const [executiveCustomer, setExecutiveCustomer] = useState(
    executiveCustomerStorage || ""
  );
  const [searchAddress, setsearchAddress] = useState(
    searchAddressStorage || ""
  );
  const [defectActNumber, setDefectActNumber] = useState(
    defectActNumberStorage || ""
  );
  const [replacementTask, setReplacementTask] = useState(
    replacementTaskStorage || ""
  );
  const [dateRange, setDateRange] = useState(
    dateRangeStorage?.filter(Boolean).length
      ? dateRangeStorage
      : [startDate, endDate]
  );

  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  const screenSize = useScreenSize();

  const token = useAppSelector((state) => state.users.token);
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);

  const isLoggedBefore = localStorage.getItem("isLoggedBefore");
  const [passwordDialog, setPasswordDialog] = useState(
    isLoggedBefore === "false" ? false : true
  );

  const { control, setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      [formFields.searchParams]: {
        [formFields.deadlineStatus]:
          status != "" ? CHART_DATA[status] : deadlineStatusStorage || 0,
        [formFields.executiveUser]: executiveUser,
        [formFields.ProtectionObjectId]: facilityName,
        [formFields.status]:
          status === globalFields.completed_intime_jobs_amount
            ? STATUS_ID[fields.completely]
            : statusStorage || "",
        [formFields.jobName]: "",
        [formFields.priority]: priorityStorage || "",
        [formFields.equipmentNumberAndName]:
          equipmentName || equipmentNumberAndNameStorage || "",
        [formFields.ResponsibleCustomerUserName]: responsibleUser,
        [formFields.deadLineDate]: deadLineDateStorage || "",
        [formFields.equipmentType]: equipmentTypeId,
      },
    },
  });

  const [printTaskList, { errorPrint, isLoadingPrint }] =
    useLazyPrintTaskListQuery();

  const { data: gridcolumns } = useGetUserGridSettingQuery({
    gridSettingName: "JobList",
  });

  const [getUserGrid] = useLazyGetUserGridSettingQuery();

  useEffect(() => {
    if (notif) {
      setOpenTaskDialog(true);
      setSelectedTaskId(notif);
    }
  }, [notif]);

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const protectionObjectId = watch(
    `${formFields.searchParams}.${formFields.ProtectionObjectId}`
  );
  const deadLineDate = watch(
    `${formFields.searchParams}.${formFields.deadLineDate}`
  );

  const {
    setIsErrorRequest,
    setSortParamsExecutiveUser: setExecutiveUser,
    setSortParamsFacilityName: setFacilityName,
  } = useAction();

  const [
    getTasksListTable,
    { error, isLoading: isLoadingTaskList, isFetching },
  ] = useLazyGetTasksListQuery();

  const handlePasswordDialog = () => setPasswordDialog(!passwordDialog);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        jobName,
        setJobName,
        equipmentNumberAndName,
        setEquipmentNumberAndName,
        executiveCustomer,
        setExecutiveCustomer,
        responsibleCustomer,
        setResponsibleCustomer,
        daterange: dateRange,
        setdaterange: setDateRange,
        searchAddress,
        setsearchAddress,
        defectActNumber,
        setDefectActNumber,
        replacementTask,
        setReplacementTask,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage, dateRange]
  );

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage =
      JSON.parse(sessionStorage.getItem("tasksListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem(
      "tasksListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
  }

  function clearFiltersHandler() {
    setJobName("");
    setResponsibleCustomer("");
    setExecutiveCustomer("");
    setsearchAddress("");
    setDefectActNumber("");
    setReplacementTask("");
    setDateRange([null, null]);
    reset();
    sessionStorage.removeItem("tasksListSearchParams");
  }

  // const resetHeaderTableForm = () => {
  //   reset();
  //   setJobName("");
  //   setEquipmentNumberAndName("");
  //   setExecutiveUser("");
  //   setResponsibleCustomer("");
  //   setFacilityName("");
  // };

  const rows = useMemo(() => {
    const { data } = tasks;
    return data?.length
      ? data.map(
          ({
            taskId,
            taskName,
            facilityAddress,
            jobEquipments,
            defectActNumber,
            replacementTasks,
            ...task
          }) => {
            const { city, street, building, extension } = facilityAddress || {};
            const formattedAddress = [city, street, building, extension]
              .filter((item) => !!item)
              .join(", ");
            let equipment = {};
            if (jobEquipments.length > 0)
              equipment = {
                id: jobEquipments[0].id,
                name: `${jobEquipments[0].integrationNumber ? `${jobEquipments[0].integrationNumber},` : ""} ${jobEquipments[0].name}`,
                action: () => handleOpenEquipment(jobEquipments[0].id),
              };
            return {
              jobName: {
                taskId,
                taskName,
                action: () => handleOpenTaskDialogState(taskId),
              },
              jobEquipments:
                jobEquipments.length > 0
                  ? jobEquipments.map(function ({ id, name }) {
                      return {
                        id,
                        name,
                        action: () => handleOpenEquipment(id),
                      };
                    })
                  : [],
              facilityAddress: formattedAddress,
              defectActNumber,
              replacementTasks:
                replacementTasks.length > 0
                  ? replacementTasks.map(function ({ id, name }) {
                      return {
                        id,
                        name,
                        action: () => handleOpenTaskDialogState(id),
                      };
                    })
                  : [],
              ...task,
            };
          }
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const recordsCount = useMemo(() => tasks && tasks?.recordsCount, [tasks]);
  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    writeSearchDataToLocalStorage("deadLineDateStorage", deadLineDate);
  }, [deadLineDate]);

  useEffect(() => {
    writeSearchDataToLocalStorage(
      "protectionObjectIdStorage",
      protectionObjectId
    );
  }, [protectionObjectId]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.executiveUser}`,
      executiveUser
    );
  }, [executiveUser, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("dateRangeStorage", dateRange);
    setValue(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`,
      dateRange
    );
  }, [dateRange, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage(
      "equipmentNumberAndNameStorage",
      equipmentNumberAndName
    );
    setValue(
      `${formFields.searchParams}.${formFields.equipmentNumberAndName}`,
      equipmentNumberAndName
    );
  }, [equipmentNumberAndName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ResponsibleCustomerUserName}`,
      responsibleCustomer
    );
  }, [responsibleCustomer, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ExecutiveUserName}`,
      executiveCustomer
    );
  }, [executiveCustomer, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.ProtectionObjectId}`,
      facilityName === "все" ? "" : facilityName
    );
  }, [facilityName, setValue]);
  useEffect(() => {
    writeSearchDataToLocalStorage(
      "equipmentNumberAndNameStorage",
      equipmentName
    );
    setValue(
      `${formFields.searchParams}.${formFields.equipmentNumberAndName}`,
      equipmentName
    );
  }, [equipmentName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.jobName}`, `${jobName}`);
  }, [jobName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.replacementTask}`,
      `${replacementTask}`
    );
  }, [replacementTask, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.defectActNumber}`,
      `${defectActNumber}`
    );
  }, [defectActNumber, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ProtectionObjectAddressFilter`,
      searchAddress
    );
  }, [searchAddress, setValue]);
  useEffect(() => {
    if (status)
      setValue(
        `${formFields.searchParams}.${formFields.deadlineStatus}`,
        CHART_DATA[status]
      );
  }, [status, setValue]);

  useEffect(() => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`
    );
    delete params.CreatedDateRange;
    delete params.ProtectionObjectAddressFilter;
    delete params.equipmentType;
    const city = searchAddress?.city
      ? `&ProtectionObjectAddressFilter.City=${searchAddress?.city}`
      : "";
    const street = searchAddress?.street
      ? `&ProtectionObjectAddressFilter.Street=${searchAddress?.street}`
      : "";
    const building = searchAddress?.building
      ? `&ProtectionObjectAddressFilter.Building=${searchAddress?.building}`
      : "";
    const address = city + street + building;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null)
        dd["CreatedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CreatedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    console.log("params.ProtectionObjectId", params);
    if (params.protectionObjectId == "все") {
      delete params.protectionObjectId;
    }
    if (params.status == "все") {
      delete params.status;
    }
    if (params.priority == "все") {
      delete params.priority;
    }

    changePageNumber(0);
    getTasksListTable({
      ...params,
      EquipmentKindId: equipmentKind,
      EquipmentType: equipmentType,
      ...dd,
      EquipmentId: equip ? equip : equipmentId != "" ? equipmentId : "",
      OnlyOpenTasks: OnlyOpenTasks,
      OrganizationResponsibleId:
        responsibleUserId != "" ? responsibleUserId : "",
      // ExecutiveUserId: executiveUserId != "" ? executiveUserId : "",
      ExecutiveUserName: executiveCustomer,
      address: address,
      PageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getTasksListData = () => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`
    );
    delete params.CreatedDateRange;
    delete params.ProtectionObjectAddressFilter;
    delete params.equipmentType;
    const city = searchAddress?.city
      ? `&ProtectionObjectAddressFilter.City=${searchAddress?.city}`
      : "";
    const street = searchAddress?.street
      ? `&ProtectionObjectAddressFilter.Street=${searchAddress?.street}`
      : "";
    const building = searchAddress?.building
      ? `&ProtectionObjectAddressFilter.Building=${searchAddress?.building}`
      : "";
    const address = city + street + building;
    console.log("adress", address);
    let dd = {};

    if (createdDate) {
      if (createdDate[1] != null)
        dd["CreatedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CreatedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    if (params.protectionObjectId == "все") {
      delete params.protectionObjectId;
    }
    if (params.status == "все") {
      delete params.status;
    }
    if (params.priority == "все") {
      delete params.priority;
    }
    getTasksListTable({
      ...params,
      EquipmentKindId: equipmentKind,
      EquipmentType: equipmentType,
      ...dd,
      EquipmentId: equip ? equip : equipmentId != "" ? equipmentId : "",
      OnlyOpenTasks: OnlyOpenTasks,
      OrganizationResponsibleId:
        responsibleUserId != "" ? responsibleUserId : "",
      // ExecutiveUserId: executiveUserId != "" ? executiveUserId : "",
      ExecutiveUserName: executiveCustomer,
      address: address,
      PageNumber,
    });
  };

  useEffect(() => {
    getTasksListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // useEffect(
  //   () => () => {
  //     resetHeaderTableForm();
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false);
  };

  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: "application/pdf" }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false);
  };

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const printTaskListWrapper = (isPrint) => {
    if (isPrint) setPrintLoading(true);
    else setLoading(true);
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.${formFields.CreatedDateRange}`
    );
    delete params.CreatedDateRange;
    delete params.ProtectionObjectAddressFilter;
    delete params.equipmentType;
    const city = searchAddress?.city
      ? `&ProtectionObjectAddressFilter.City=${searchAddress?.city}`
      : "";
    const street = searchAddress?.street
      ? `&ProtectionObjectAddressFilter.Street=${searchAddress?.street}`
      : "";
    const building = searchAddress?.building
      ? `&ProtectionObjectAddressFilter.Building=${searchAddress?.building}`
      : "";
    const address = city + street + building;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null)
        dd["CreatedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CreatedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    if (params.protectionObjectId == "все") {
      delete params.protectionObjectId;
    }
    if (params.status == "все") {
      delete params.status;
    }
    if (params.priority == "все") {
      delete params.priority;
    }
    printTaskList({
      ...params,
    }).then((data) => {
      const res = data.data;
      if (isPrint) printBase64(data.data.content);
      else base64toPDF(data.data.name, data.data.content);
    });
  };
  const itemsNav = ["list_tasks", "group_tasks"];

  return (
    <Box className="template_page">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => {}}
        />
      )}
      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={selectedTaskId}
          refetchTaskList={() =>
            // getTasksListTable({ PageNumber: PageNumber, address: "" })
            {
              getTasksListData();
              setOpenTaskDialog(false);
            }
          }
          // resetForm={resetHeaderTableForm}
        />
      )}
      {!passwordDialog && (
        <SetNewPasswordDialog
          token={token}
          open={!passwordDialog}
          firstEntered={true}
          onClose={handlePasswordDialog}
        />
      )}
      <Typography className="title-page">{t("title_page:tasks")}</Typography>
      <Box className="template_page-header">
        <PageheaderNav itemsNav={itemsNav} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {printloading ? (
            <Box className="full_screen_icon-wrapper">
              <CircularProgress
                className="full_screen_icon"
                sx={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5px",
                }}
              />
            </Box>
          ) : (
            <Box className="full_screen_icon-wrapper">
              <div
                onClick={() => printTaskListWrapper(true)}
                style={{
                  marginTop: "5px",
                  border: "0px !important",
                }}
                className="full_screen_icon"
              >
                <PrintIcon />
              </div>
            </Box>
          )}
          <Button
            className="btn btn-primary-fill"
            onClick={clearFiltersHandler}
          >
            Очистить все фильтры
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printTaskListWrapper(false)}
            disabled={loading}
            sx={{ height: "52px" }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                <Box
                  component="img"
                  src={exporticon}
                  sx={{ marginRight: "16px" }}
                />
                {t("button:create_act_pdf")}
              </>
            )}
          </Button>
          <Button
            className="btn btn-primary-fill"
            variant="outlined"
            sx={{
              height: "52px",
            }}
            onClick={() => {
              navigate(PATHS.ADD_TASK);
            }}
          >
            <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
            {t("button:add")}
          </Button>

          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box
              component="img"
              src={settingsicon}
              sx={{ marginRight: "16px" }}
            />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={isLoadingTaskList || isFetching}
        pageNumber={PageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={getTableHieght(screenSize).min}
        maxHeight={getTableHieght(screenSize).max}
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "JobList" })}
        nameStorage={"JobList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

export default TasksList;
