import React, { useMemo, useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetAllOrganizationsQuery } from "../../../middleware/apiOrganizations";
import { useForm } from "react-hook-form";
import { getRowsData } from "./helpers";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import MyOrganizationDetails from "../MyOrganizationDetails";
import { getColumns } from "./columns";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import { getConfigFormModalSelect } from "./form-config";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import plusicon from "../../../image/icons/plusBtnWhite.png";

const MyOrganizations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { allOrganizations } = useAppSelector((state) => state.organizations);
  const { setIsErrorRequest, setSortParams } = useAction();
  const { control, setValue, watch, handleSubmit, resetField } = useForm();
  const [selectedId, setSelectedId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);
  const [orgTypeFilter, setOrgTypeFilter] = useState();
  const [searchInn, setSearchInn] = useState();
  const [searchName, setSearchName] = useState();
  const [searchAddress, setSearchAddress] = useState();
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [isChoosenFirstPage, setIsChoosenFirstPage] = useState(false);

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "OrganizationList" });

  const [
    getUserGrid,
    { error: errorgrid, refetch: refetchUserGrid /* isLoading */ },
  ] = useLazyGetUserGridSettingQuery();
  const [getOrganizationsTable, { error, isLoading }] =
    useLazyGetAllOrganizationsQuery();

  function writeSearchDataToLocalStorage(
    newSearchParam,
    searchValue,
    isClearFilter = false
  ) {
    const getStorage =
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem(
      "organizationListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
    isClearFilter && setIsClearFilters(true);
  }

  useEffect(() => {
    const { searchInn, searchName, searchAddress, orgTypeFilter } =
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {};
    setSearchName(searchName);
    setSearchInn(searchInn);
    setSearchAddress(searchAddress);
    setOrgTypeFilter(orgTypeFilter);
  }, []);

  function clearFiltersHandler() {
    setSearchName();
    setSearchInn();
    setSearchAddress();
    setOrgTypeFilter();
    setIsClearFilters(true);
    const searchParamsForLocalStorage = {
      searchAddress: "",
      searchName: "",
      orgTypeFilter: "",
      phone: "",
    };
    sessionStorage.setItem(
      "organizationListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
  }

  const handleChange = (value) => {
    setOrgTypeFilter(value);
    writeSearchDataToLocalStorage("orgTypeFilter", value);
  };

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        orgTypeFilter,
        searchInn,
        setsearchInn: setSearchInn,
        searchName,
        setsearchName: setSearchName,
        searchAddress,
        setsearchAddress: setSearchAddress,
        handleChange,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const handleOpenDetails = ({ id, typeId }) => {
    setSelectedId(id);
    setTypeId(typeId);
    setOpenDiteilsPopUp(!!id);
  };

  const handleLink = (id, typeId) =>
    navigate(PATHS.GET_EDIT_MY_ORGANIZATION(id, typeId));

  const rows = useMemo(
    () => {
      const { data } = allOrganizations;
      return getRowsData(data, handleLink, handleOpenDetails);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOrganizations]
  );

  const recordsCount = useMemo(
    () => allOrganizations && allOrganizations?.recordsCount,
    [allOrganizations]
  );

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const dataFromStorage =
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {};
    const {
      searchInn: searchInnStorage,
      searchAddress: searchAddressStorage,
      searchName: searchNameStorage,
      orgTypeFilter: orgTypeFilterStorage,
    } = dataFromStorage;
    const city = searchAddressStorage?.city
      ? `&AddressFilter.City=${searchAddressStorage?.city}`
      : "";
    const street = searchAddressStorage?.street
      ? `&AddressFilter.Street=${searchAddressStorage?.street}`
      : "";
    const building = searchAddressStorage?.building
      ? `&AddressFilter.Building=${searchAddressStorage?.building}`
      : "";
    const address = city + street + building; //+emptyDTO
    if (
      pageNumber === 0 &&
      !Object.values(dataFromStorage).filter(Boolean).length &&
      !isClearFilters
    )
      return;
    getOrganizationsTable({
      searchValue: "",
      pageNumber: 0,
      InnNumber: searchInnStorage || "",
      address: address,
      name: searchNameStorage || "",
      orgTypeFilter: orgTypeFilterStorage || "",
    });
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInn, searchAddress, searchName, orgTypeFilter]);

  useEffect(() => {
    const dataFromStorage = Object.values(
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {}
    ).filter(Boolean);
    const city = searchAddress?.city
      ? `&AddressFilter.City=${searchAddress?.city}`
      : "";
    const street = searchAddress?.street
      ? `&AddressFilter.Street=${searchAddress?.street}`
      : "";
    const building = searchAddress?.building
      ? `&AddressFilter.Building=${searchAddress?.building}`
      : "";
    const address = city + street + building; //+emptyDTO
    if (dataFromStorage.length && pageNumber === 0 && !isChoosenFirstPage)
      return;
    getOrganizationsTable({
      searchValue: "",
      pageNumber,
      InnNumber: searchInn || "",
      address: address,
      name: searchName || "",
      orgTypeFilter: orgTypeFilter || "",
    });
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  return (
    <Box className="legal-entity">
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {t("title_page:legal_entities")}
        </Typography>
        <Box sx={{display: 'flex', gap: "16px"}}>
          <Button
            className="btn btn-primary-fill"
            onClick={clearFiltersHandler}
          >
            Очистить все фильтры
          </Button>
          <Button
            className="btn btn-primary-fill "
            variant="outlined"
            onClick={() => {
              navigate(PATHS.ADD_MY_ORGANIZATION);
            }}
          >
            <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
            {t("button:add")}
          </Button>

          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box
              component="img"
              src={settingsicon}
              sx={{ marginRight: "16px" }}
            />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

      <TableBasic
        columns={columns}
        rows={rows || []}
        handleClickCell={() => {}}
        pageNumber={pageNumber}
        withSearchField={false}
        changePageNumber={changePageNumber}
        isLoading={isLoading}
        count={recordsCount}
        setIsChoosenFirstPage={setIsChoosenFirstPage}
        minHeight="52vh"
        maxHeight="60vh"
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "OrganizationList" })}
        nameStorage={"OrganizationList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

      <MyOrganizationDetails
        open={openDiteilsPopUp}
        setOpen={setOpenDiteilsPopUp}
        id={selectedId}
        typeId={typeId}
      />
    </Box>
  );
};

export default MyOrganizations;
