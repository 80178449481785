import React from "react";
import Box from "@mui/material/Box";

import { ADDRESSES_TYPES } from "./constants";
import CheckboxSimple from "../../components/CheckboxSimple";
import InputText from "../../components/InputText";
import InputFileSingle from "../../components/InputFile/InputFileSingle";
import InputMaskPhone from "../../components/InputMaskPhone";
// import YandexMapInput from "../../components/YandexMapInput";
import { fileSizeCheck, fileTypeCheck } from "../../../validation";
import { SelectOrganizationTypes, SelectSimpleJobTitles } from "../EmployeeList/Components";
import { AddressInput } from "../../components/AddressInput";


export const formFields = {
  typeId: "typeId",
  name: "name",
  ogrnNumber: "ogrnNumber",
  innNumber: "innNumber",
  kppCode: "kppCode",
  ur_address: "ur_address",
  fact_address: "fact_address",
  address: "address",
  okpoCode: "okpoCode",
  okvedCode: "okvedCode",
  jobChart:"jobChart",
  bankName: "bankName",
  account: "account",
  correspondentAccount: "correspondentAccount",
  bankBIC: "bankBIC",
  file: "file",
  organizationResponsibleAddresses:"organizationResponsibleAddresses",
  organizationAddresses: "organizationAddresses",
  extension: "extension",
  bankDetails: "bankDetails",
  city: "city",

  typeName: "typeName",
  tasks: "tasks",
  edit: "edit",
  orgTypeFilter: "orgTypeFilter",
};
export const allColumns = [
  { name: formFields.innNumber, showName: "innNumber", value: true },
  { name: formFields.typeName, showName: "organizationType", value: true },
  { name: formFields.name, value: true, showName: "name" },
  { name: formFields.organizationAddresses,showName:"address", value: true },
  { name: formFields.tasks,showName:"tasks", value: true },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
export const EMPTY_VALUES = {
  name: "",
  ogrnNumber: "",
  kppCode: "",
  okpoCode: "",
  okvedCode: "",
  file: "",
  licenseNumber: "",
  licenseExpirationDate: null,
  bankDetails: {
    bankName: "",
    account: "",
    correspondentAccount: "",
    bankBIC: "",
  },
  organizationAddresses: [],
  organizationResponsibleBankDetails: {
    bankName: "",
    account: "",
    correspondentAccount: "",
    bankBIC: "",
  },

  organizationResponsibleAddresses: [],

  organizationResponsibleContracts: [
    {
      organization: null,
      equipmentKind: null,
      contractNumber: "",
      expirationDate: "",
      contractFile: {
        filesPrev: [],
        filesToAdd: [],
        filesToDelete: [],
      },
    },
  ],
};

export const fields = {
  organization_customer: "organization_customer",
  organization_contractor: "organization_contractor",
  outside_organization: "outside_organization",
/*   control_and_executive_organization: "control_and_executive_organization", */
};

export const templatePerson = {
  [formFields.fullName]: "",
  [formFields.phone]: "",
  [formFields.email]: "",
  [formFields.position]: "",
};

export const templateAddress = {
  address: "",
  type: "Фактический",
};

export const listOfOrganisationsConfig = [
  {
    name: formFields.typeId,
    label: "label:organizationType",
    type: "text",
    height: "56px",
    displayEmpty:true,
    placeholder: "placeholder:facilityType",
    rules: {
      required: true,
    },
    disabled: false,
    input: (props) => (
      <Box sx={{width: '49%', "& .MuiMenu-paper": {height: 'fit-content !important'}}}>
        <SelectOrganizationTypes {...props}  />
      </Box>
    ),
  },
];

export const formConfigInn = [
  {
    name: formFields.innNumber,
    label: "label:innNumber",
    type: "number",
    placeholder: "placeholder:inn",
    rules: {
      required: true,
      maxLength: (v) => v?.length <= 12,
      validate: (value, formValues) => value >=0 ,
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    input: (props) => (
      <Box sx={{ width: "49%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.name,
    label: "label:name",
    placeholder: "placeholder:facilityName",
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => {
      return (
        <Box sx={{ minWidth: "250px" }}>
          <InputText {...props} />
        </Box>
      );
    },
  },
];

export const formConfigOrganization = [
  // {
  //   name: formFields.name,
  //   label: "label:name",
  //   placeholder: "placeholder:organizationName",
  //   type: "text",
  //   rules: {
  //     required: true,
  //   },
  //   input: (props) => {
  //     return (
  //       <Box sx={{ minWidth: "250px" }}>
  //         <InputText {...props} />
  //       </Box>
  //     );
  //   },
  // },
  {
    name: formFields.ogrnNumber,
    label: "label:ogrnNumber",
    placeholder: "placeholder:ogrn",
    type: "number",
    rules: {
      required: false,
      maxLength: (v) => v?.length <= 15,
      validate: (value) => {
        if (!value) return true;
        if (value.length === 0) return true;
        if (value.length >= 1 && value.length <= 12) return false;
        if (value.length > 12 && value.length < 16) return true;
        return false;
      } 
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    input: (props) => (
      <Box sx={{ }}>
        <InputText {...props} />
      </Box>
    ),
  },
  /*{
    name: formFields.file,
    label: "label:orgnFile",
    rules: {
      
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.file,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.file,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: false,
    preview: false,
    uploadBtnText: "button:uploadSingle",
    input: (props) => (
      <Box sx={{  }}>
        <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
      </Box>
    ),
  },*/
];

export const formConfigBasicInf = [
  {
    name: formFields.kppCode,
    label: "label:kppCode",
    placeholder: "placeholder:kpp",
    type: "number",
    rules: {
      required: false,
      maxLength: (v) => v?.length <= 9,
      validate: (value, formValues) => value?.length === 9,
    },
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.okpoCode,
    label: "label:okpoCode",
    placeholder: "placeholder:okpo",
    type: "number",
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.okvedCode,
    maxLength:{ value: 8 },
    rules: {

      // maxLength: (v) => v?.length <= 8,
     // validate: (value, formValues) => !/[^0-9.]/.test(value) && value?.trim().slice(-1) !== '.' && (value?.length === 8 || value?.length === 5 || value?.length === 6 || value?.length === 4 || value?.length === 7),
      
    },
    onKeyDown: (event) => {

      /*if(isNaN(e.key) && e.key !== 'Backspace' || e.target.value.length >7 && e.key !== 'Backspace' || e.key === 'Tab') {
        e.preventDefault();
      }*/
      console.log("event",event.target.value,event.target.value.length)
      if ((event.key === "Tab" || event.key === "Backspace" || (event.ctrlKey && event.key === "v")) || (event.key >= "0" && event.key <= "9") && event.target.value.length < 8) {
        return;
      }else{
        event.preventDefault();
      }
      

    
    },
    placeholder: "placeholder:okved",
    label: "label:okvedCode",
    type: "text",
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
];

export const formConfigBank = [
  {
    name: formFields.bankBIC,
    label: "label:bik",
    placeholder: "placeholder:bik",
    type: "number",

    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
 
   
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.bankName,
    label: "label:name_bank",
    placeholder: "placeholder:bankName",
    type: "text",
    rules: {
      required: false,
    },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.account,
    label: "label:rs_organization",
    placeholder: "placeholder:rbill",
    type: "number",
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    rules: {
      required: false,
      // maxLength: (v) => v?.length <= 20,
      // validate: (value, formValues) => value?.length === 20,
    },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.correspondentAccount,
    label: "label:kor_schet",
    placeholder: "placeholder:corrbill",
    type: "number",
    onKeyDown:(e) => {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "." || e.key === ",") {
        e.preventDefault()
      }
    },
    InputProps:{ type:"number" },
    rules: {
      required: false,
      // maxLength: (v) => v?.length <= 20,
      // validate: (value, formValues) => value?.length === 20,
    },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  
];

export const formConfigPerson = [
  {
    name: formFields.fullName,
    label: "label:fullName",
    placeholder: "placeholder:fullName",
    type: "text",
    firstUpperCase: true,
    rules: {
      required: true,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.phone,
    placeholder: "placeholder:phone",
    label: "label:phone",
    rules: {
      required: true,
      validate: (value, formValues) =>
        value.replace(/[+() ]/g, "", "")?.length === 11,
    },
    input: (props) => <InputMaskPhone {...props} />,
  },
  {
    name: formFields.email,
    label: "label:email",
    placeholder: "placeholder:email",
    type: "text",
    rules: {
      required: true,
      pattern:
        /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,}$/g,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.position,
    label: "label:position",
    
    type: "text",
    rules: {
      required: true,
    },
    input: (props) => <SelectSimpleJobTitles {...props} />,
  },
];

export const formConfigAddress = [
  {
    name: formFields.address,
    label: "label:yandex_address",
    placeholder: "placeholder:address",
 
    shouldSetCoordinates: false,
    input: (props) => <AddressInput {...props} />,
  },
  {
    name: formFields.extension,
    type: "number",
    placeholder: "placeholder:place",
    label: "label:extension",
    rules: {
      required: false,
    },
    input: (props) => <InputText {...props} />,
  },
];
