import React from "react";
import { t } from "i18next";
import { Box, Button } from "@mui/material";
import { formFields } from "./form-config";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { eventsOptions } from "./options";
import { SelectSimpleObject } from "../EquipmentList/Components";
import DateRangePickerNew from "../../components/DateRangePickerNew/DateRangePickerNew";
import {
  DateFormatCell,
  ClickableJustFullNameCell,
  SimpleTextCell,
  JustFullNameCell,
} from "./Components";

export const getColumns = ({
  control,
  messageClassSearch,
  setMessageClassSearch,
  taskName,
  setTaskName,
  equipName,
  setequipName,
  roomPlume,
  setroomPlume,
  triggerCause,
  settriggerCause,
  executiveUser,
  setexecutiveUser,
  controlConsoleName,
  setControlConsoleName,
  responsibleUser,
  setresponsibleUser,
  daterange,
  setdaterange,
  columnsFromStorage,
  handleOpenEquipment,
  writeSearchDataToLocalStorage,
}) => {
  return [
    {
      id: formFields.reactionDateTime,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:dateAndTime"),
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.reactionDateTime],
      RowCell: (props) => <DateFormatCell {...props} />,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.ReactionDateRange`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("placeholder:dateRange")}
                setdaterange={setdaterange}
                daterange={daterange}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.facility,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facility],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.FacilityId}`}
          label={t("label:object")}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
              sx={{ width: "100%" }}
            />
          )}
        />
      ),
    },
    {
      id: formFields.messageClass,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.messageClass],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.MessageClass`}
            placeholder={t("tableHeader:event_class")}
            sx={{
              padding: "9px 0 9px 16px",
              margin: "0",
              width: "100%",
            }}
            input={(props) => (
              <SearchInbuilt
                className="select_in_built small-light"
                searchValue={messageClassSearch}
                changeSearchValue={setMessageClassSearch}
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="messageClassSearchStorage"
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.roomAddressPlume,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:roomAddressPlume"),
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.roomAddressPlume],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.RoomPlume`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "300px",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:roomAddressPlume")}
                className="select_in_built small-light"
                searchValue={roomPlume}
                changeSearchValue={setroomPlume}
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="roomPlumeStorage"
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.equipmentName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:equipment"),
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentName],
      RowCell: (props) => (
        <Button
          className="table-row-cell-link"
          sx={{ verticalAlign: "initial", textAlign: "left" }}
          onClick={() => handleOpenEquipment(props.row.equipmentId)}
        >
          {props.row.equipmentName}
        </Button>
      ),
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.EquipmentNameAndNumber`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equipment")}
              className="select_in_built small-light"
              searchValue={equipName}
              changeSearchValue={setequipName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="equipNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.triggerCause,
      numeric: false,
      enableSorting: false,
      size: "240px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.triggerCause],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.TriggerCause`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "240px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:triggerCause")}
              className="select_in_built small-light"
              searchValue={triggerCause}
              changeSearchValue={settriggerCause}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="triggerCauseStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.reportingPlace,
      numeric: false,
      enableSorting: false,
      size: "210px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.reportingPlace],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.eventsFilter}`}
          label={t("label:events")}
          type={"select-text"}
          options={eventsOptions}
          input={(props) => (
            <Box className="medium_container">
              <SelectSimpleInbuilt
                className="select_in_built small-light"
                {...props}
              />
            </Box>
          )}
        />
      ),
    },
    {
      id: formFields.responsible,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:r_organization"),
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.responsible],
      RowCell: (props) => <JustFullNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.ResponsibleCustomerUser`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "250px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:r_organization")}
              className="select_in_built small-light"
              searchValue={responsibleUser}
              changeSearchValue={setresponsibleUser}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="responsibleUserStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.executive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:executive"),
      size: "280px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.executive],
      RowCell: (props) => <ClickableJustFullNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.ExecutiveUser`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "280px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:executive")}
              className="select_in_built small-light"
              searchValue={executiveUser}
              changeSearchValue={setexecutiveUser}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="executiveUserStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:task"),
      size: "175px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.jobName],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.TaskName}`}
          sx={{
            padding: "9px 0 9px 16px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:task")}
              className="select_in_built small-light"
              searchValue={taskName}
              changeSearchValue={setTaskName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="taskNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.controlConsoleName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:controlConsoleName"),
      size: "175px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.controlConsoleName],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.controlConsoleName}`}
          sx={{
            padding: "9px 0 9px 16px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:controlConsoleName")}
              className="select_in_built small-light"
              searchValue={controlConsoleName}
              changeSearchValue={setControlConsoleName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="controlConsoleNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
  ].filter(({ isVisible }) => isVisible);
};
