import React, { useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { CloseIcon } from "../../../components/icons/CloseIcon";
import {
  formConfigBasicInformation,
  formFields,
} from "../AddInformationBlock/form-config";
import FormItem from "../../../components/FormItem";
import TextEditor from "../AddInformationBlock/TextEditor";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useEditNewsMutation } from "../../../../middleware/usersApi";
import { useAction } from "../../../../hooks/actions";
import { getRequestDataFilesForAdd } from "../AddInformationBlock/helpers";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";

const EditNewsModal = ({ isVisible, handleClose, newsDataForEdit }) => {
  const {
    content,
    date,
    id,
    city,
    title,
    priority,
    photoFileId,
    photoFileName,
  } = newsDataForEdit || {};
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(content || "");
  const [editNews] = useEditNewsMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [
    manipulateFiles,
    // { error: errorFile, isSuccess: isSuccessFile, isLoading: loadingFiles },
  ] = useManipulateFilesMutation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.priority]: priority,
      [formFields.date]: date || "",
      [formFields.city]: city || "",
      [formFields.title]: title || "",
      [formFields.image]: {
        filesPrev: [{ fileName: photoFileName, fileLink: photoFileId }],
        filesToAdd: [],
        filesToDelete: [],
      },
    },
  });

  const onSubmit = (data) => {
    const imageData = data.image
    delete data.image
    const preparedData = { ...data, content: editorState, id };
    editNews(preparedData).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
        return;
      }

      if (imageData) {
        const requestDataFiles = getRequestDataFilesForAdd(
          id,
          "News",
          imageData
        );
        manipulateFiles(requestDataFiles).then((response) => {
          if ("error" in response) {
            setIsErrorSubmit(response.error);
          }
          setIsSuccessSubmit(true);
          reset();
          handleClose();
          return;
        });
      }
      setIsSuccessSubmit(true);
      reset();
      handleClose();
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="edit-news-modal">
        <Box className="edit-news-modal-header">
          <Typography>Редактирование новости</Typography>

          <Box onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <form
          className="add-news-form"
          id="editNews"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formConfigBasicInformation.map(
            ({ name, label, input, ...rest }, i) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            )
          )}

          <TextEditor
            editorState={editorState}
            setEditorState={setEditorState}
          />

          <Button form="editNews" className="btn addNews" type="submit">
            {t("button:save")}
          </Button>
        </form>
      </Box>
    </Dialog>
  );
};

export default EditNewsModal;
