import React from "react";
import { Box, Dialog, Typography } from "@mui/material";
import { CloseIcon } from "../../../components/icons/CloseIcon";
import "./styles.css";

const FullInfoOperativeNewsModal = ({ isVisible, handleClose, newsData }) => {
  const { content, date } = newsData || {};

  const createMarkup = () => {
    return { __html: content };
  };

  const renderDescription = () => {
    return <Box dangerouslySetInnerHTML={createMarkup()} />;
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="edit-news-modal">
        <Box className="edit-news-modal-header">
          <Typography>Оперативная информация МЧС</Typography>

          <Box onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", color: "var(--mainText)" }}>
          {new Date(date).toLocaleDateString("ru-RU")}
          {renderDescription()}
        </Box>
      </Box>
    </Dialog>
  );
};

export default FullInfoOperativeNewsModal;
