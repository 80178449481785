import React from "react";
import { t } from "i18next";
import { formFields } from "./form-config";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import {
  ClickableSchemaCell,
  SelectSimpleObject,
  SelectSimpleFloor,
  ClickableEquipmentCell,
} from "./Components";
import { SimpleTextCell } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import DateRangePickerNew from "../../components/DateRangePickerNew/DateRangePickerNew";

const statusOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Да" },
  { value: "false", name: "Нет" },
];
const lifeTimeDateOptions = [
  { value: "все", name: "все" },
  { value: "true", name: "Превышен" },
  { value: "false", name: "Не превышен" },
];

export const getColumns = ({
  control,
  columnsFromStorage,
  searchNumber,
  setSearchNumber,
  // searchIntegrationNumber,
  // setSearchIntegrationNumber,
  searchRoomAddressName,
  setSearchRoomAddressName,
  searchObjectIntegrationNumber,
  setSearchObjectIntegrationNumber,
  searchName,
  setSearchName,
  objectId,
  searchPlume,
  setSearchPlume,
  searchReplacingEquipmentName,
  setSearchReplacingEquipmentName,
  lastServicedaterange,
  setlastServicedaterange,
  replacementDatedaterange,
  setreplacementDatedaterange,
  writeSearchDataToLocalStorage,
  // equipmentKindIdStorage,
}) =>
  [
    {
      id: formFields.number,
      numeric: false,
      enableSorting: false,
      size: "200px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.number],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchNumber}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "300px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:number")}
              className="select_in_built small-light"
              searchValue={searchNumber}
              changeSearchValue={setSearchNumber}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchNumberStorage"
              {...props}
            />
          )}
        />
      ),
    },
    /*{
      id: formFields.integrationNumber,
      numeric: false,
      enableSorting: false,
      size: "250px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.integrationNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchIntegrationNumber}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:intNumber")}
              className="select_in_built small-light"
              searchValue={searchIntegrationNumber}
              changeSearchValue={setSearchIntegrationNumber}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchIntegrationNumberStorage"
              {...props}
            />
          )}
        />
      ),
    },*/
    {
      id: formFields.protectionObjectName,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: true,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.protectionObjectName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("tableHeader:protectionObjectName")}
          name={`${formFields.searchParams}.${formFields.protectionObjectId}`}
          sx={{
            padding: "13px 0 13px 16px",
            width: "232px",
          }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              objectId={objectId || ""}
              IsTableheader={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.floorNumber,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:floorNumber"),
      size: 150,
      clickable: true,
      isVisible: columnsFromStorage[formFields.floorNumber],
      RowCell: (props) => <ClickableSchemaCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.floorId}`}
          label={t("tableHeader:floorNumber")}
          sx={{
            padding: "13px 0 13px 16px",
            width: "170px",
          }}
          input={(props) => (
            <SelectSimpleFloor
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="floorIdStorage"
              storageName="equipmentsListSearchParams"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.roomAddressName,
      numeric: false,
      enableSorting: false,
      size: "200px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.roomAddressName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchRoomAddressName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "210px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:roomAddressName")}
              className="select_in_built small-light"
              searchValue={searchRoomAddressName}
              changeSearchValue={setSearchRoomAddressName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchRoomAddressNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.objectIntegrationNumber,
      numeric: false,
      enableSorting: false,
      size: "300px",
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.objectIntegrationNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchObjectIntegrationNumber}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "300px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:intNumber")}
              className="select_in_built small-light"
              searchValue={searchObjectIntegrationNumber}
              changeSearchValue={setSearchObjectIntegrationNumber}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchObjectIntegrationNumberStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.equipmentKindId}`}
          label={t("label:equipKinds")}
          sx={{
            padding: "13px 0 13px 16px",
            width: "300px",
          }}
          input={(props) => (
            <EquipmentKindSelectGrouping
              {...props}
              itemoption={{
                id: 0,
                name: t("options_name:everything"),
              }}
              isTableheader={true}
              useShortTitle={true}
              className="select_in_built small-light"
              // value={equipmentKindIdStorage}
              // writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              // storageValue="equipmentKindIdStorage"
              // storageName="equipmentsListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.name,
      numeric: false,
      enableSorting: false,
      size: "225px",
      clickable: true,
      isSticky: false,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.name],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "230px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equipName")}
              className="select_in_built small-light"
              searchValue={searchName}
              changeSearchValue={setSearchName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.plume,
      numeric: false,
      enableSorting: false,
      size: "70px",
      clickable: true,
      isSticky: false,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.plume],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchPlume}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "140px",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:plume")}
              className="select_in_built small-light"
              searchValue={searchPlume}
              changeSearchValue={setSearchPlume}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchPlumeStorage"
              {...props}
            />
          )}
        />
      ),
    },

    {
      id: formFields.lifeTimeDate,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.lifeTimeDate],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.explotationExceeded}`}
          label={t("tableHeader:lifeTimeDate")}
          options={lifeTimeDateOptions}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          width="240px"
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="lifeTimeDateStorage"
              storageName="equipmentsListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.lastServiceDate,
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage[formFields.lastServiceDate],
      label: t("tableHeader:lastServiceDate"),
      size: "410px",
      clickable: false,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.lastServiceDate`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("placeholder:lastServicedateRange")}
                setdaterange={setlastServicedaterange}
                daterange={lastServicedaterange}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.replacementDate,
      numeric: false,
      enableSorting: false,
      isVisible: columnsFromStorage[formFields.replacementDate],
      label: t("tableHeader:replacementDate"),
      size: "300px",
      clickable: false,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.replacementDate`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("placeholder:replacedateRange")}
                setdaterange={setreplacementDatedaterange}
                daterange={replacementDatedaterange}
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.replacingEquipment,
      numeric: false,
      enableSorting: false,
      size: "280px",
      clickable: true,
      isSticky: false,
      borderHeader: true,
      isVisible: columnsFromStorage[formFields.replacingEquipment],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.searchReplacingEquipmentName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:replacingEquipment")}
              className="select_in_built small-light"
              searchValue={searchReplacingEquipmentName}
              changeSearchValue={setSearchReplacingEquipmentName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchReplacingEquipmentNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      enableSorting: false,
      mode: "diagram",
      label: t("tableHeader:tasks"),
      size: "78px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.tasks],
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            padding: "13px 16px 13px 16px",
            fontSize: "16px",
          }}
        >
          {t("tableHeader:tasks")}
        </div>
      ),
    },
    {
      id: formFields.workingProperly,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:workingCorrectly"),
      size: "240px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.workingProperly],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.workingCorrectly}`}
          label={t("tableHeader:workingCorrectly")}
          options={statusOptions}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          width="100%"
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="workingProperlyStorage"
              storageName="equipmentsListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.isReplaced,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isReplaced"),
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isReplaced],
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.isReplaced}`}
          label={t("tableHeader:isReplaced")}
          options={statusOptions}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          width="165px"
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="isReplacedStorage"
              storageName="equipmentsListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      mode: "edit",
      isVisible: true,
      size: 50,
      clickable: false,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            height: "50px",
          }}
        ></div>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
