import CheckboxSimple from "../../components/CheckboxSimple";

export const nameFields = {
  allEvents: "AllEvents",
  alerts: "Alerts",
  malfunctions: "Malfunctions",
  automation: "Automation",
  maintenance: "Maintenance",
  normal: "normal",
};
export const optionFields = {
  allEvents: -1,
  alerts: 1,
  normal: 0, 
  malfunctions: 2,
  automation: 3,
  maintenance: 100,
};

export const formFields = {
  events: "events",
  controlConsoleName:"controlConsoleName",
  reactionDateTime: "reactionDateTime",
  facility: "facility",
  messageClass: "messageClass",
  roomAddressPlume: "roomAddressPlume",
  equipmentName: "equipmentName",
  triggerCause: "triggerCause",
  executive: "executive",
  responsible: "responsible",
  jobName: "jobName",
  TaskName:"TaskName",
  FacilityId:"FacilityId",
  fullName: "fullName",
  contractor: "contractor",

  facilitySearch: "facilitySearch",
  eventsFilter: "eventsFilter",
  messageClassSearch: "messageClassSearch",
  reportingPlace: "reportingPlace",
  dateAndTime: "dateAndTime",
  r_organization: "r_organization",
  kind_of_message: "kind_of_message",
  event_class: "event_class",
  task: "task",
  equipment: "equipment",

  searchParams: "searchParams",
  organization: "organization",
};

export const DEFAULT_PARAMS = {
  facilitySearch: "",
  eventsFilter: optionFields.allEvents,
  messageClassSearch: "",
};

export const allColumns = [
  {
    name: formFields.reactionDateTime,
    value: true,
    showName: formFields.dateAndTime,
  },
  { name: formFields.facility, value: true },
  { 
    name: formFields.messageClass,  
    value: true,
    showName: formFields.event_class,
  },
  { name: formFields.roomAddressPlume, value: true },
  { 
    name: formFields.equipmentName, 
    value: true,
    showName: formFields.equipment,
  },
  { name: formFields.triggerCause, value: true },
  { 
    name: formFields.reportingPlace,
    value: true,
    showName: formFields.kind_of_message,
  },
  {
    name: formFields.responsible,
    value: true,
    showName: formFields.r_organization,
  },
  { name: formFields.executive, value: true },
  { 
    name: formFields.jobName, 
    value: true,
    showName: formFields.task,
  },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
