import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Slider,
  CircularProgress,
} from "@mui/material";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { formFields } from "../form-config";
import { useLazyGetFloorInfoSelectQuery } from "../../../../middleware/fireCompartmentApi";
import {
  useLazyGetEquipmentsCoordinatesQuery,
  useGetEquipmentNumbersQuery,
  useCalculateFloorSchemeParamsMutation,
} from "../../../../middleware/equipmentsApi";
import MissingFloorSchemaForm from "./MissingFloorSchemaForm";
import { modalAddressConfig } from "../form-config";
import ModalRoomAddress from "../ModalRoomAddress";
import SchemaWithEquipmentsAddEquip from "../../../components/DrawCanvas/SchemaWithEquipmentsAddEquip";
import { getRoomAddressForScheme } from "../../../../helpers/helper";
import { EditDrawingPlume } from "../../../components/DrawCanvas/EditCanvas";
import { DrawingPlume } from "../../../components/DrawCanvas/DrawingPlume";
import { useAppSelector } from "../../../../hooks/redux";

const ImageSchemeSimple = ({
  items,
  control,
  isLite,
  errors,
  setValue,
  getValues,
  resetField,
  isDrawPoligon,
  setIsDrawPoligon,
  equipmentCoordinatesChange,
  setequipmentCoordinatesChange,
  transformCanvasData,
  setTransformCanvasData,
  setoptimalscale,
  setoptimaloffsetAddEquip,
  setminScale,
  openEditModal,
  setisTurnDrag,
  isTurnDrag,
  addplume,
  minScale,
  scaleAddEquip,
  setisFocusID,
  isFocusID,
  setFocusId,
  setscaleAddEquip,
  screenRelatedWidth,
  screenRelatedHeight,
  scale,
  imageRef,
  canvasRef,
  setFloorScheme,
  widthWorkplace,
  heightWorkplace,
  newRoomAddresses,
  setNewRoomAddresses,
  optimaloffsetAddEquip,
  optimalscale,
  floorScheme,
  searchIntNum,
  setsearchIntNum,
  setisloadScheme,
  isloadScheme,
  turnOnEdit,
  selectShape,
  isEditShape,
  selectedId,
  setTurnOnEdit,
  offsetAddEquip,
  xyGroup,
  setxyGroup,
  setoffsetAddEquip,
  objectId,
  focusId,
  onRightClick,
}) => {
  const { t } = useTranslation();

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const {
    fireCompartmentId,
    equipmentsCount = 1,
    equipmentsCoordinates,
    name,
    floor: floor,

    workingCorrectly,
    equipmentTypeId,
    roomAddresses,
  } = useWatch({
    control,
  });

  const [remainder, setRemainder] = useState(+equipmentsCount);
  const [isChangeFloor, setisChangeFloor] = useState(false);
  const [floorsOptions, setFloorsOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isFirstTimeLoading, setisFirstTimeLoading] = useState(true);
  const [titleCompartment, setTitleCompartment] = useState("");
  const [image, setImage] = useState(null);
  const [numberIndex, setnumberIndex] = useState(0);
  const [removedAddress, setRemovedAddress] = useState([]);
  const [fullscreen, setFullScreen] = useState(false);
  const [isFloorSelect, setIsFloorSelect] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isCalculated, setisCalculated] = useState(false);
  const { SCHEMA_CANVAS1, openSidebar } = useAppSelector(
    (state) => state.settings
  );
  const imageWidth = imageRef.current?.width();
  const imageHeight = imageRef.current?.height();

  const $container = useRef(null);
  const [widthContainer, setWidthContainer] = useState(0);
  const [calculateFloorScheme, { }] = useCalculateFloorSchemeParamsMutation();
  const [getFloorOptions] = useLazyGetFloorInfoSelectQuery();
  const [
    getEquipmentsCoordinates,
    { isLoading: isEquipmentsCoordinatesLoading },
  ] = useLazyGetEquipmentsCoordinatesQuery();

  const calculateFloorOptions = () => {
    if (image) {
      if (
        image?.width != null &&
        image?.height != null &&
        image?.width != 0 &&
        image?.height != 0
      ) {
        setisCalculated(true)
        calculateFloorScheme({
          FloorInfoId: floor,
          CanvasHeight: parseInt(heightWorkplace),
          CanvasWidth: parseInt(widthWorkplace),
          MinimumDistance: 25,
        }).then(({ data }) => {
          const scaleX = screenRelatedWidth / SCHEMA_CANVAS1.WIDTH;
          const scaleY = screenRelatedHeight / SCHEMA_CANVAS1.HEIGHT;
          setoptimalscale(data.optimalScale);
          var scrollX = data.bottomLeftX * scaleX + widthWorkplace / 2;
          var scrollY = data.bottomLeftY * scaleY + heightWorkplace / 2;
          if (data.optimalScale === 1) {
            scrollX = 0;
            scrollY = 0;
          }
          if (scrollX > 0) {
            scrollX = 0;
          }
          if (scrollY > 0) {
            scrollY = 0;
          }
          console.log("why1",focusId)
          setoptimaloffsetAddEquip({ x: scrollX, y: scrollY });
          setscaleAddEquip(data.optimalScale);
          setminScale(data.optimalScale);
          setisChangeFloor(false)
          
          if (focusId == null || focusId == "") {
            setoffsetAddEquip({ x: scrollX, y: scrollY });
          } 
          
        });
      }
    }
  };

  useEffect(() => {
    console.log(`floor1: ${floor} screenRelatedHeight: ${screenRelatedHeight} screenRelatedWidth: ${screenRelatedWidth}`)
    setisloadScheme(true)
  }, [ screenRelatedHeight, screenRelatedWidth]);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setIsFloorSelect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainder, setRemainder]);

  useEffect(() => {
    console.log("why3",isCalculated)
    if(isFocusID){
      if (focusId != null && focusId != "" && !isChangeFloor) {
        console.log("why",focusId)
        const equipFind = equipmentsCoordinates?.find((obj) => obj.id == focusId);
        
        if (equipFind) {
          setisFocusID(false);
          if (scaleAddEquip < minScale) setscaleAddEquip(minScale);
          const scaleX = screenRelatedWidth / SCHEMA_CANVAS1.WIDTH;
          const scaleY = screenRelatedHeight / SCHEMA_CANVAS1.HEIGHT;
          const scalle = scaleAddEquip < minScale ? minScale : scaleAddEquip;
          setoffsetAddEquip({
            x:
              -equipFind.floorSchemeCoordinateX * scalle * scaleX +
              widthWorkplace / 2,
            y:
              -equipFind.floorSchemeCoordinateY * scalle * scaleX +
              heightWorkplace / 2,
          });
        } else {
          setscaleAddEquip(optimalscale);
          setoffsetAddEquip(optimaloffsetAddEquip);
        }
      } 
    }
    else {
      setFocusId("")
    }
  }, [focusId,isChangeFloor]);

  const screenRelatedRoomAddresses = useMemo(() => {
    return getRoomAddressForScheme(roomAddresses, scale);
  }, [roomAddresses, scale]);

  useEffect(() => {
    
    if (image) {
      if (
        image?.width != null &&
        image?.height != null &&
        image?.width != 0 &&
        image?.height != 0 
      ) {
        console.log(`floor1:`,image)
        if ((isChangeFloor || isFirstTimeLoading) && isloadScheme) {
         
          setminScale(null)
          calculateFloorOptions();
         // setisChangeFloor(false);
          setisloadScheme(false)
          setisFirstTimeLoading(false)
        }
      }
    }
  }, [image]);

  useEffect(() => {
    if (!floor) {
      return;
    }
    setisChangeFloor(true);
    (async function () {
      const res = await getEquipmentsCoordinates(
        `id=${floor}&returnEquipments=${true}&returnRooms=${true}&returnJobCharts=${false}`
      );
      if (res?.data) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          res.data;
        setFloorScheme(floorSchemeFileLink);
        setValue(formFields.roomAddresses, roomAddresses);

        const coordinates =
          equipmentCoordinates.length > 0
            ? equipmentCoordinates.map((item) => ({
              ...item,
              floorInfoId: floor,
            }))
            : [];

        setValue(formFields.equipmentsCoordinates, coordinates);
        setTurnOnEdit(false);
        setIsDrawPoligon(false);
        setNewRoomAddresses([]);

        setRemovedAddress([]);
      }
      localStorage.setItem(
        "currentAddEquipObject",
        JSON.stringify({ object: objectId, floor: floor })
      );
      
    })();
  }, [floor, getEquipmentsCoordinates, setValue, getValues]);

  useEffect(() => {
    if (equipmentsCoordinates && equipmentsCoordinates?.length) {
      const countNewIcons = equipmentsCoordinates.filter(({ id }) =>
        id.includes("new")
      )?.length;
      setRemainder(+equipmentsCount - countNewIcons);
    }
  }, [equipmentsCoordinates, equipmentsCount]);
  console.log("minScale",minScale)
  return (
    <>
      <Box
        ref={$container}
        className={`schema-field ${!floorScheme ? "schema-field-image-missing" : ""}`}
        sx={{ marginLeft: "20px" }}
      >
        {/* <Box className="schema-field-menu">
            <Box className="schema-field-menu-form">
              {items?.map(({ id, name, label, input, expended, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  options={name === formFields.floor ? floorsOptions : []}
                  input={input}
                  disabled={turnOnEdit}
                  {...rest}
                />
              ))}
            </Box>
            <Box className="schema-field-menu-items">
              <Typography className="schema-field-menu-items-title">
                {t("title:icon_dnd")}
              </Typography>
              <Box className="schema-field-menu-items-row">
                <Draggable
                  id={"fire"}
                  type={"fire"}
                  className="schema-field-menu-items-row-icon"
                >
                  {remainder > 0 && (
                    <img
                      src={equipmentTypeId && getEquipmentIcon(JSON.parse(equipmentTypeId)?.id || '')}
                      alt={t(name)}
                    />
                  )}
                </Draggable>
                <Typography className="schema-field-menu-items-row-label">{`${remainder} из ${equipmentsCount},Имя: ${equipNumbers[numberIndex]}`}</Typography>
              </Box>
            </Box>
          </Box>
*/}

          <Box className={`schema-field-image`}>
            {floorScheme ? (
              !isEquipmentsCoordinatesLoading ?
              addplume ? (
                <DrawingPlume
                  isLite={isLite}
                  isAddEquip={true}
                  setValue={setValue}
                  width={screenRelatedWidth || 0}
                  height={screenRelatedHeight || 0}
                  control={control}
                  scaleAddEquip={scaleAddEquip}
                  setscaleAddEquip={setscaleAddEquip}
                  setoffsetAddEquip={setoffsetAddEquip}
                  offsetAddEquip={offsetAddEquip}
                  schemaImg={floorScheme}
                  setisTurnDrag={setisTurnDrag}
                  isTurnDrag={isTurnDrag}
                  imageRef={imageRef}
                  canvasRef={canvasRef}
                  widthWorkplace={widthWorkplace}
                  heightWorkplace={heightWorkplace}
                  minScale={minScale}
                  isDrawPoligon={isDrawPoligon}
                  setsearchIntNum={setsearchIntNum}
                  transformCanvasData={transformCanvasData}
                  setTransformCanvasData={setTransformCanvasData}
                  searchIntNum={searchIntNum}
                  roomAddresses={screenRelatedRoomAddresses}
                  getValues={getValues}
                  resetField={resetField}
                  newRoomAddresses={newRoomAddresses}
                  setNewRoomAddresses={setNewRoomAddresses}
                />
              ) : turnOnEdit ? (
                <EditDrawingPlume
                  isAddEquip={true}
                  width={screenRelatedWidth || 0}
                  height={screenRelatedHeight || 0}
                  control={control}
                  facilityId={objectId}
                  schemaImg={floorScheme}
                  isDrawPoligon={isDrawPoligon}
                  isEditShape={isEditShape}
                  scaleRoom={scale}
                  canvasRef={canvasRef}
                  scaleAddEquip={scaleAddEquip}
                  setscaleAddEquip={setscaleAddEquip}
                  setoffsetAddEquip={setoffsetAddEquip}
                  widthWorkplace={widthWorkplace}
                  heightWorkplace={heightWorkplace}
                  transformCanvasData={transformCanvasData}
                  setTransformCanvasData={setTransformCanvasData}
                  offsetAddEquip={offsetAddEquip}
                  isTurnDrag={isTurnDrag}
                  minScale={minScale}
                  imageRef={imageRef}
                  setsearchIntNum={setsearchIntNum}
                  searchIntNum={searchIntNum}
                  roomAddresses={screenRelatedRoomAddresses}
                  getValues={getValues}
                  selectShape={selectShape}
                  xyGroup={xyGroup}
                  setxyGroup={setxyGroup}
                  selectedId={selectedId}
                  setValue={setValue}
                  resetField={resetField}
                  newRoomAddresses={newRoomAddresses}
                  setNewRoomAddresses={setNewRoomAddresses}
                />
              ) : (
                <SchemaWithEquipmentsAddEquip
                  withScrollbars
                  focusId={focusId}
                  isChangeFloor={isChangeFloor}
                  setisloadScheme={setisloadScheme}
                  isloadScheme={isloadScheme}
                  onRightClick={onRightClick}
                  name={formFields.equipmentsCoordinates}
                  width={screenRelatedWidth || 0}
                  height={screenRelatedHeight || 0}
                  schemaImg={floorScheme}
                  setequipmentCoordinatesChange={setequipmentCoordinatesChange}
                  equipmentCoordinatesChange={equipmentCoordinatesChange}
                  xyGroup={xyGroup}
                  minScale={minScale}
                  transformCanvasData={transformCanvasData}
                  setTransformCanvasData={setTransformCanvasData}
                  widthWorkplace={widthWorkplace}
                  heightWorkplace={heightWorkplace}
                  setxyGroup={setxyGroup}
                  scaleAddEquip={scaleAddEquip}
                  setscaleAddEquip={setscaleAddEquip}
                  setoffsetAddEquip={setoffsetAddEquip}
                  offsetAddEquip={offsetAddEquip}
                  image={image}
                  setImage={setImage}
                  imageRef={imageRef}
                  openEditModal={openEditModal}
                  canvasRef={canvasRef}
                  roomAddresses={screenRelatedRoomAddresses}
                  isPlan={true}
                  setValue={setValue}
                  control={control}
                  draggable={true}
                  title={titleCompartment}
                  fullscreen={fullscreen}
                  setFullScreen={setFullScreen}
                />
              )
            : <CircularProgress/>
            ) : (
              <MissingFloorSchemaForm
                floor={floor}
                setFloorScheme={setFloorScheme}
                width={screenRelatedWidth}
              />
            )}
          </Box>
       
      </Box>

      <ModalRoomAddress
        open={open}
        setOpen={setOpen}
        config={modalAddressConfig}
      />
    </>
  );
};

export default ImageSchemeSimple;
